<template>
  <div>
    <div
      class="contact-detail"
      style="background-image: url('images/background.jpeg')"
    >
      <div class="content">
        <h3 class="mb-35">Contact Us</h3>
        <p>Have a question or need assistance?</p>
        <p>We are here to help you with anything you need!</p>
      </div>
    </div>
    <div class="more-detail">
      <a-row type="flex" :gutter="24">
        <a-col :span="12" :xs="24" :md="12">
          <div class="contact-list">
            <a-row type="flex" :gutter="24">
              <a-col :span="24" class="mb-24">
                <div>
                  <a-icon
                    type="mail"
                    theme="twoTone"
                    two-tone-color="red"
                    style="
                      font-size: 40px;
                      /* background-color: #008000; */
                      padding: 10px;
                      /* color:#008000 */ */
                      
                      border-radius: 10px;
                    "
                  />
                  <h5>Email</h5>
                  <span> d89.media@gmail.com</span>
                </div></a-col
              >
              <a-col :span="24" class="mb-24">
                <div>
                  <a-icon
                    type="phone"
                    theme="twoTone"
                    two-tone-color="green"
                    style="
                      font-size: 40px;
                      /* background-color: #008000; */
                      padding: 10px;
                      border-radius: 10px;
                    "
                  />
                  <h5>Phone</h5>
                  <span> +85620 5888 8463, 9999 2263</span>
                </div>
              </a-col>
              <a-col :span="24" class="mb-24">
                <div>
                  <a-icon
                    type="bank"
                    theme="twoTone"
                    style="
                      font-size: 40px;
                      /* background-color: #008000; */
                      padding: 10px;
                      border-radius: 10px;
                    "
                  />
                  <h5>Address</h5>
                  <span>
                    Dondeng Village, Chanthabouly District, Vientiane Capital,
                    Laos</span
                  >
                </div></a-col
              >
            </a-row>
          </div>
        </a-col>
        <a-col :span="12" :xs="24" :md="12">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.443546957364!2d102.60824389999999!3d17.996582800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312469f0e1519807%3A0xb97b03c79a14ffa1!2sThavisub%20Broker%20Insurance!5e1!3m2!1sen!2sla!4v1676360221619!5m2!1sen!2sla"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contact-detail {
  height: 300px;
  margin-top: -120.8px;
  padding-top: 80px;
  border-radius: 12px;
  // box-shadow: $shadow-1;
  background-size: cover;
  background-position: center;
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: 100px) {
    margin-top: -62.8px;
  }

  * {
    color: #fff;
    // color: $color-gray-1;
  }

  .content {
    padding-top: 40px;
    max-width: 480px;
    margin: auto;

    h1 {
      font-size: 36px;

      @media (min-width: 480px) {
        font-size: 48px;
      }
    }
  }
}

.more-detail {
  padding-top: 10px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-size: cover;
  background-position: center;
  text-align: center;
  margin-bottom: 20px;

  .contact-list {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 20px;
  }
  .map {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
</style>
